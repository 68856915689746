export default {
    main: {
        repeatLogin: 'Signed in from another device',
		connectionFailed: 'Network connection failed',
        recieveNewMyTodoList: 'Receive a new assignment',
        todoListDone: 'Finished assignment',
        shiftChangeNotice: 'shift update notice',
    },
    login: {
        login: 'Login',
        id: 'ID',
        phone: 'Phone',
        account: 'ID / Phone',
        password: 'Password',
        passwordConfirm: 'Confirm Password',
        rememberAccount: 'Remember Me',
        forgetPassword: 'Forget Password',
        register: 'Register',
        choosePartner: 'Please select an institution',
        failedAlert1: 'Login Failed',
        failedAlert2: 'Please enter correct data',
        failedAlert3: 'Wrong password',
        failedAlert4: 'Can not find the user',
        failedAlert5: 'The account was closed',
        failedAlert6: 'Wrong password over 4 times, please try again 15 minutes later.',
        loginFailed: 'Login Failed',
        mobile: 'Mobile',
        back: 'Back Login',
        submit: 'Submit',
        sendVerifyCode: 'Verification code delievred',
        firstName: 'First Name',
        lastName: 'Last Name',
        mobileVerifyCode: 'Phone Verification Code.',
        mobileVerify: 'Verification',
        timeLeft: 'Time left',
        seconds: 'sec',
        agree: 'I Agree',
        selectType: 'Choose privilege',
        selectTitle: 'Choose title',
        deliverFailed: 'Fail to deliver verification code',
		deliverSuccessful: 'Verification code delievred',
        privacy: 'Agreement',
        confirmLogin:'Please contact customer service to assign your account to a valid institution.'
    },
    navbar: {
        doctorsStatus: 'Contacts',
        offline: 'Offline',
        updateInfo: 'Release updates',
        reservation: 'Appointments',
        chat: 'messages',
        onlineStatus: 'Online',
        busyStatus: 'Busy',
        offlineStatus: 'Offline',
        profile: 'About Me',
        notify: 'Notification',
        changePartner: 'Switch Institutioon',
        logout: 'Logout',
        newVersionReload: 'The background host system has been updated, please refresh the window immediately to ensure the correctness of the data.',
        later: 'Later',
        reload: 'Reload',
        closeReload: 'Without refreshing the window the may not ensure the data correctness. Are you sure to continue?',
        choosePartnerGroup: 'Please select an institution',
        Online:'Online',
        Offline:'Offline'
    },
    home: {
        todayAppointment: 'Appointments',
        futureAppointment: 'Future Appts',
        chatRoomMsg: 'Chat Messages',
        toDoList: 'To-Dos',
        todayGeneralIdea: 'Today',
        weekGeneralIdea: 'This Week',
        monthGeneralIdea: 'This Month',
        todayAppointmentUint: '',
        futureAppointmentUint: '',
        chatRoomMsgUint: '',
        toDoUint: '',
        reservationTotal: 'Appts',
        checkInTotal: 'Check-ins',
        finishTotal: 'Finished',
        peopleTotal: 'people',
        Mon: 'Mon.',
        Tue: 'Tue.',
        Wed: 'Wed.',
        Thu: 'Thu.',
        Fri: 'Fri.',
        Sat: 'Sat.',
        Sun: 'Sun.',
        resetPasswordOrNot: 'If you want to reset your password',
        doctorJoinMsg: 'Welcome to join {partnerName}, Please fullfil your data and submit for approval. Thank you very much.',
        doctorPhoneVerifyMsg: 'Welcome to join {partnerName}, Please fullfil your mobile verify. Thank you very much.',
    },
    reservation: {
        am: 'AM',
        pm: 'PM',
        night: 'Night',
        other: 'Other Shift',
        emergency: 'Emergency',
        agent: 'Agent Appt.',
        special: 'Special Appt.',
        record: 'Appt. Records',
        otherRWD: 'Other',
        emergencyRWD: 'Emergency',
        agentRWD: 'Agent Appt.',
        specialRWD: 'Special Clinic',
        recordRWD: 'Record',
        card: 'Card',
        table: 'Table',
        doctor: 'Doctor',
        status: 'Status',
        all: 'All',
        unfinish: 'Unfinished',
        finish: 'Finished',
        cancel: 'Cancel Registered',
        cancelSystem: 'System Cancel Registered',
        specialReservation: 'New Special Appt.',
        selectDoctorMember: 'Please select an Doctor Member.',
        selectPatientMember: 'Please Filter an Patient',
        selectDate: 'Please select a Date.',
        selectTime: 'Please select a time.',
        startDate: 'Start Date',
        endDate: 'End Date',
        enterMinutes: 'Select Plan',
        enterPrice: 'Please enter price',
        tip1: 'Please select an Patient Member ID or Patient Name.',
        tip2: 'Both parties will be reminded 30 minutes before the selected time.',
        vipMember: 'VIP Member',
        search: 'Search',
        noData: 'No Appointment!',
        confirmText_1: 'Please confirm the following information',
        confirmText_2: 'Appointments Doctor: ',
        confirmText_3: 'Patient Name: ',
        confirmText_4: 'Payment plan: ',
        confirmText_5: 'min',
        confirmText_6: 'Reservation Time: ',
        confirmText_7: 'Period: ',
        confirmText_8: 'Notice：Please remind the patient to complete the payment within 30 minutes。',
        sms: 'Message',
        unSay: 'Not Description',
        minutesPlan: 'minutes plan',
        medicalRecord: 'MR',
        contactAgent: 'Contact caregiver',
        minutes: 'min',
        seconds: 'sec',
        weChatCall: 'WeChat Call',
        healthlinkCall: 'Healthlink Call',
        lineMsg: 'LINE message',
        sms: 'Send Link',
        smsText: 'You will send a link to invite the patient join the room.You will receive a notification while the patient join the room.',
		smsTextCancel: 'The member missed the call, the registration has been cancelled.',
        man: 'Man',
        woman: 'Woman',
        downloadReport: 'Dowload',
        pdfDownload: 'PDF',
        excelDownload: 'Excel',
        patientList: 'patient list',
        invite: 'invite',
        hasInvited: 'has invited',
        notInRoom: 'The patient is not in room',
        isWaitting: 'The patient is waiting in room',
        EnterRoom: 'Enter Room',
        times: 'times',
        hasWaited: 'has waited',
        copyLink: 'Copy link',
        copyLinkNotify: 'Video chat link has been copied to clipboard',
        site: '[Onsite visit]',
        switchNewVersion: 'New Version',
        Inroom:'is waiting in room',
        patient:"Patient",
        familyMembers:"’s family member"
    },
    apptMgmt: {
        otherInstitution: 'Others Appts',
        my: 'My',
        myself: 'Me',
        whosAppt: '{name} Appts',
        myReferralAppt: 'My Referrals',
        authDoc: 'Other staffs',
        switchOldVersion: 'Old version',
        agent: 'General Appt.',
        special: 'Special Appt.',
        filter: 'Filter',
        todayType: 'Today',
        futureType: 'Future',
        today: 'Today',
        future: 'Future',
        am: 'AM',
        pm: 'PM',
        night: 'Night',
        spec: 'Special',
        emergency: 'Emergency',
        status: 'Status (All)',
        unfinish: 'Unfinished',
        finish: 'Finished',
        cancel: 'Cancelled',
        cancelSystem: 'System Cancel Registered',
        call: 'Call',
        invite: 'Invite',
        enterRoom: 'Enter Room',
        goToMr: 'MR',
        sendMsg: 'Messaging',
        copyLink: 'Copy Room Link',
        notInRoom: 'Room is empty',
        isWaitting: 'Member is waiting in room',
        tag: '{name} Referred',
        tag2: 'Call to {name}',
        noData: 'No data available',
        shortSpec: 'Special',
        onsiteVisit: 'Onsite visit',
        freeClinicUntested: '{num} untested',
        freeClinicTested: '{num} tested',
        callBack: 'To: ',
        csTags: 'consult with {dname}',
        consultation: 'Consultation',
    },
    advisory: {
        filter: 'Filter',
        today: 'Today',
        week: 'Week',
        month: 'Month',
        season: 'Season',
        year: 'Year',
        search: 'Search',
        searchNameAndPhone: 'Search Name Or Phone',
        searchNameAndPhoneAndAccount: 'Search Name, Phone Or Account',
        startDate: 'Start Date',
        endDate: 'End Date',
        agent: 'Appointment',
        emr: 'View MR',
        video: 'Video',
        seconds: 'sec',
        quarterDefinition: '* Quarter definition : Yearly 4 quarters are 1-3 ｜ 4-6 ｜ 7-9 ｜ 10-12',
        nodata: 'No data found.',
        recordFile: 'Record File',
    },
    todolist: {
        todolist: 'My To-Dos',
        ask: 'Assign',
        process: 'Processing',
        finish: 'Done',
        nodata: 'No data found.',
        toAsk: 'Assigned To-Dos',
        sendTo: 'Select',
        content: 'Content',
    },
    chat: {
        searchContact: 'Search Contact',
        patient: 'Patient',
        noContact: 'Please go to Members menu, find your tartet member, and start chatting.',
        noMore: 'No more',
        loading: 'Loading',
        medicalStaff: 'Medical staff',
        addMRStaff: 'Add Medical staff',
        searchMRStaff: 'Search Medical staff',
        noMRStaff: 'No Medical staff',
        group: 'Group',
        groupName: 'Group Name',
        addGroup: 'Add Group',
        noGroup: 'No Group',
        sendMsg: 'Send Message',
        submit: 'Submit',
        enterGroupName: 'Please enter the group name',
        selectGroupMember: 'Please select members of the group',
        reselectUser: 'Please select users again',
        selectUser: 'Please select users',
        excelExport: 'Export as Excel',
        msgIsSeen: 'Read',
        msgIsRecycle: 'Click to recycle this message',
        recycleMsg: 'This Message has been recycle',
        alertRecycleMsg: 'This Message will able to recycle! Are you sure to recycle This Message?'
    },
    notify: {
        title: 'Notifications',
        nodata: 'No Notification',
    },
    member: {
        totalRecord: '',
        pleaseSelect: 'Please select',
        selectGroup: 'Please select an group.',
        all: 'All',
        searchNameAndAccount: 'Search Name Or Account',
        search: 'Search',
        card: 'Card',
        table: 'Table',
        patientNoAuth: 'Not get patient\'s authorization yet',
        personalSick: 'Personal Medical History',
        none: 'None Personal sick',
        patient: 'Patient',
        transfer: 'Transfer',
        medicalRecord: 'MR',
        upload: 'Upload',
        agent: 'Appointment',
        pleaseClick: 'Please Click',
        noData: 'No data found.',
    },
    membersMgmt: {
        prev: 'Back',
        title: 'Members',
        filter: 'Filter',
        unit: 'All Units',
        tags: 'CRM Case Types',
        disease: 'Disease history (All)',
        dataLength: 'records',
        male: 'M',
        female: 'F',
        noAuth: 'EMR is not authorized',
        noDisease: 'No disease history',
        goToMr: 'EMR',
        call: 'Call',
        sendMsg: 'Message',
        agent: 'Make an Appt.',
        special: 'Special Appt.',
        upload: 'Upload Files',
        addCase: 'Create a CRM Case',
        noData: 'No data available',
        callTitle: 'Select a Person to Call',
    },
    monitor: {
        title: 'Remote Monitoring',
        refresh: 'Refresh',
        autoRefresh: 'Auto',
        selectUnit: 'Please select an unit.',
        all: 'All',
        allDays: 'All measurements',
        oneDay: 'Within 1 Day',
        threeDays: 'Within 3 Days',
        sevenDays: 'Within 7 Days',
        thirtyDays: 'Within 30 Days',
        temperature: 'Body Temperature',
        weight: 'Weight',
        pulse_rate: 'Pulse',
        blood_oxygen: 'Blood Oxygen',
        blood_sugar_before: 'Glucose-AC',
        blood_sugar_after: 'Glucose-PC',
        blood_sugar_empty: 'Glucose',
        systolic_blood_pressure: 'Systolic Pressure',
        diastolic_blood_pressure: 'Diastolic Pressure',
        pulse_rate_bpm: 'Pulse Rate Bpm',
        electrocardiography:'Electrocardiography',
        noData: 'No data found.',
        noHealthData: 'No Data',
        man: 'Man',
        woman: 'Woman',
        min: 'Min',
        max: 'Max',
        monitorNoAuth: 'Remote monitoring is disabled',
        noMonitorGroup: 'No remote monitoring groups, which can be created in Partner Platform by partner managers.',
        prev: 'Back',
    },
    opinion: {
        opinion: 'Consultations',
        beInvited: 'Be Invited',
        invite: 'To invite',
        beInvitedRWD: 'Be Invited',
        inviteRWD: 'To invite',
        startDate: 'Start Date',
        endDate: 'End Date',
        detail: 'Detail',
        process: 'Processing',
        finish: 'Done',
        noData: 'No data found.',
        content: 'Content',
        mr: 'EMR',
        generalPhoto: 'Photograph',
		generalReport: 'PE Report',
		dicom: 'DICOM',
		funduscopy: 'Funduscope',
		ITRI_AI: 'ITRI_Funduscope_AI',
		ultrasonograph: 'Ultrasonograph',
		ecg: 'ECG',
		faceDiagnosticScope: 'Face diagnostic scope',
		ear: 'Ear',
		nasal: 'Nasal',
		throat: 'Throat',
		oral: 'Oral',
		skin: 'Skin',
		phono: 'Phono',
		video: 'Video',
    },
    crm: {
        pageTitle: "CRM Cases",
        addCase: "Add a Case",
        addNotes: "Add a Comment",
        caseNotes: "Case Comments",
        tracking: "Track",
        track: "Open",
        today: "Today",
        tomorrow: "Tomorrow",
        days: "in {days} Days",
        daysAgo: "{days} Days ago",
        assign: "Assign",
        assignTo: "{from_name} has assigned to {to_name}",
        caseAssign: "Assgin the Case",
        createdBy: "{name} created",
        noData: 'No data found.',
        patient: 'Patient',
        doctor: 'Owner',
        caseTitle: 'Case Title',
        caseType: 'Tags',
        notes: 'Comment',
        finish: 'Closed',
        finished: 'Closed',
        cancel: 'Restored',
        commentHistory: 'Past Comments',
        selectCaseOwner: 'Select the case owner',
        sec: '{num} secs ago',
        min: '{num} mins ago',
        hour: '{num} hours ago',
        day: '{num} days ago',
        month: '{num} months ago',
        year: '{num} years ago',
        assignMsg: '[System] {from_name} assigned to {to_name}.',
        cancelAssignMsg: '[System] {from_name} cancelled the assignment.',
        closeAll: 'Close all',
        sendMsg: 'Message',
        agent: 'Make an Appt.',
        special: 'Special Appt.',
        confirmAllClose: 'Confirm to close all my cases？',
        close_success: 'All my cases are closed.',
        noData: 'No data',
        myself: '(Myself)',
        unassigned: 'unassigned',
        selectCase: 'Select the cases needed to be closed:',
        assignCase: 'Cases assigned to me.',
        unassignedCase: 'Cases unassigned',
        detailFinish:'[System] Closed by{dname}',
        closeAll:'Close all',
        close:'Close',
        confirm:'Confirm'
    },
    schedule: {
        schedule: 'Scheduled',
        addSchedule: 'Add Scheduled',
        setTime: 'Setup time',
        sendTime: 'Send time',
        fail: 'Failed',
        finish: 'Finish',
        hasSend:'has send',
        notSend:'not send yet',
        noData: 'No data found.',
        editSchedule: 'Edit Schedule',
        selectDateTime: 'Select date and time',
        content: 'Content',
        update: 'Update',
        patient: 'Patient',
        medicalStaff: 'Medical Staff',
        selectPatient: 'Please search an Patient Member.',
        selectDoctor: 'Please select an Doctor Member.',
        Topatient:'To Patient:',
        ToStaff:'To Staff:'
    },
    shift: {
        shift: 'Shifts',
        myShift: 'My Shift',
        maxAppointment: 'Appointments per Shift',
        people: '',
        fixedSchedule: 'Fixed Schedule',
        arrangeShift: 'Arrange Shifts',
        changeTime: 'Change',
        startTime: 'Start Time',
        endTime: 'End Time',
        alertText1: 'The change will affect',
        alertText2: 'registered patients. You have to notify those patients about the change. Do you want to make the change?',
        mon: 'Mon.',
        tue: 'Tue.',
        wed: 'Wed.',
        thu: 'Thu.',
        fri: 'Fri.',
        sat: 'Sat.',
        sun: 'Sun.',
        am: 'AM',
        pm: 'PM',
        night: 'Night',
        amRWD: 'AM',
        pmRWD: 'PM',
        nightRWD: 'Night',
        shiftNote: 'Can only be scheduled for dates after today.(Excluding today)',
        scheduledOff: 'Scheduled Off',
        cancelDayOff: 'Cancel day off setting',
        setOffDay: 'Off day is set',
        ShiftSetting:'Shifts Setting By Weekdays ',
        closed:'Closed'
    },
    authorization: {
        authorization: 'Authorization',
        addAuth: 'Authorize',
        manageShift: 'Manage Shift',
        contactPatient: 'Contact Appt. Patient',
        search: 'Search',
        delete: 'Delete',
        prompt:'Only Medical Decision Role can grant  privileges to others.',
    },
    contact: {
        title: 'Doctor Info',
        subtitle: 'Search and select those doctors and nurses you want to work with.',
        search: 'Search',
        addContact: 'Add Contact',
        existContact: 'Exist Contact',
        totalRecord: '',
        noData: 'No data found.',
        deleteContactAlert: 'Are you sure to delete the contact person?',
    },
    setWord: {
        title: 'Can Words',
        desc: 'Set up frequent use canned words will save your typing time. Canned words type : Subject、Object、Advice and Text chat.',
        import: 'Import',
        addWord: 'Add Word',
        noData: 'No data found.',
        edit: 'Edit',
        download: 'Download template',
        uploadFile: 'Upload file',
        previewData: 'Preview data',
        finish: 'Done',
        downloadExample: 'Download sample template.',
        onlyExcelFile: 'Only Excel file is acceptable.',
        dragFile: 'Drag the file here or <em>click</em> upload.',
        doctorSOAP: 'Doctor S.O.A.P. type.',
        noDoctorSOAP: 'Can not find medical staff\'s S.O.A.P type',
        prevStep: 'Previous',
        nextStep: 'Next',
        importMultiple: 'Import multiple records',
        soap_S: 'Subjectivity',
        soap_O: 'Objectivity',
        soap_Memop: 'Medical Staff Advice',
        soap_WD: 'Word Chat',
        diet:'diet suggestion',
        diet_Memop: 'Diet suggestion',
        waring:'Please modify can words before saving!',
    },
    profile: {
        title: 'About Me',
        id: 'ID',
        name: 'Name',
        email: 'Email',
        doctorStatus: 'Status',
        privileges: 'Privileges',
        title: 'Title',
        mobile: 'Mobile',
        mobileVerify: 'Verification',
        mobilePass: 'Phone is verified',
        mobileNotPass: 'Phone is not verified',
        edit: 'Edit',
        resetPassword: 'Edit Password',
        serviceUnit: 'Institution',
        serviceType: 'Service Types',
        serviceList: 'Service List',
        addServiceList: 'Add Service',
        add: 'Add',
        noData: 'No data found.',
        lang: 'Service Lagaunges',
        fee: 'Consultation Fees',
        dayFee: 'Day Rate',
        nightFee: 'Emg\'t & Night Rate',
        dayFeeChina: 'Day Rate - China',
        nightFeeChina: 'Emg\'t & Night Rate - China',
        NTs: 'NT',
        CNs: 'CN',
        queue: 'per Appt',
        division: 'Divisions',
        educationExperience: 'Education / Experience',
        bankInfo: 'Bank Information',
        bankName: 'Bank Name',
        bankBranch: 'Branch',
        bankAddress: 'Address',
        bankAccount: 'A/C No.',
        bankAccountName: 'A/C Name',
        bankSwiftCode: 'SwiftCode',
        otherInfo: 'More Information',
        personalPhoto: 'Photo',
        licensePhoto: 'Medical License',
        selectFile: 'Select File',
        selectFileTip: 'File limit 2 MB',
        firstName: 'First Name',
        lastName: 'Last Name',
        enterText: 'Enter Text Please',
        internalMD: 'Internal Medicine',
		surgical: 'Surgical',
		otherSubjects: 'Other Subjects',
		chineseMD: 'Chinese Medicine',
        education: 'Education',
        experience: 'Experience',
        position:'Position',
        selectDomesticOrForeign: 'Domestic And Foreign Banks',
        resetPasswordTip: 'Change Password will be Logout, use New Password Login。',
        enterNewPW: 'New Password',
		enterNewPWAgain: 'New Password Again',
        resetPWPlaceholder: 'New password, at least 6',
        changeMobile: 'Change Mobile',
        changeMobileTip: 'Enter a new phone number, get a verification code.',
        enterMobile: 'Enter Phone Number',
        enterVerifyCode: 'Enter verification code',
        timeLeft: 'Time left',
        seconds: 'sec',
		invalidVerifyCodeTimeLimit: 'The verification code is valid for 5 minutes.',
        outPatient: 'Out-patient',
        online: 'Video Chat',
		mainlandChinaService: 'Mainland China Service',
        volunteerOnline: 'Volunteer online service for vulnerable people',
        onsite: 'Onsite',
        localeBank: 'Taiwan\'s bank',
		foreignBank: 'Non-Taiwan bank',
        inProcess: 'In Process',
        onBoard: 'On Board',
        removeServiceUnitComfirm: 'The operation will remove the belonged service institution',
        removeServiceUnitSuccessful: 'Remove the belonged service institution done.',
		deliverVerificationSuccessful: 'Verification code delievred',
        deliverVerificationFailed: 'Fail to deliver verification code',
        changePWSuccessful: 'Password was changed successfully. Please sign in with the new password.',
        changePWFailed1: 'Please enter a password with at least 6 digits',
        changePWFailed2: 'Please enter the same password again',
        changePWFailed3: 'Please enter a mixed alphanumeric password',
		enterCorrectPhoneNumber: 'Please enter correct mobile phone number',
		verificationCodeRequired: 'Verification code is required',
		imgOnlyTips: 'Upload file must be a picture format',
		sizeLimitTips: 'Picture file can not over 20MB',
        phoneReqiredTips: 'Welcome to join CurDr., Please fullfil your mobile verify. Thank you very much.',
        uploadCheck:'Please upload .jpg or .png file format'
    },
    log: {
        title: 'Operation Logs',
        startDate: 'Start Date',
        endDate: 'End Date',
        noData: 'No data found.',
    },
    privacy: {
        mainTitle: 'RegistrationAgreement and Privacy Policy',
        title1: 'Warning:',
        desc1: 'Each country has its specific regulation about what and how a physiciancan do healthcare for a customer by means of telecommunication. As a physician, I agree to comply to the regulation. Tele-healthcare service is more suitableforgeneral healthcare consultation, or as a supplement before or after giving a treatment to a customer.',
		title2: 'Indemnification of Curdoctor:',
		desc2: 'I understand Curdoctor only provides a communication platform for healthcare practitioners to server their customers. It does not represent a healthcare practitioner or a medical institution and does not participate in the healthcare or medical activity between a customer and a healthcare practitioner either. Therefore, I agree to indemnify Curdoctor against any disputes and claim with a customer as a result of using of this platform.',
		title3: 'Authorize Record:',
		desc3: 'I agree and authorize Curdoctor to record of my consultation service as an evidence for collection of consultation fee.',
		title4: 'Service fee:',
		desc4: 'I agree and authorize Curdoctor to collect consultation fee from customer according to the rate I set. I agree to share 30% of the net fee income, after deducting 3% financial process fee, to Curdoctor. Curdoctor should transfer the monthly balance after deducting bank’s wire transfer fee to my designated bank account by15th day of next month.',
		title5: 'Use of customer data:',
		desc5: 'I understand once the customer initiates a consultation with me, it means customer authorizes me and the other necessary assistants, including consultation doctors, nurses, to check his/her data. I guaranty I’ll not copy, transfer or sell his/her data to unauthorized or unnecessary third parties.',
		title6: 'Cancellation of membership right:',
		desc6: 'In order to maintain the quality of service, I understand and agree Curdoctor have the right to cancel my membership unilaterally if I use the service improperly or maliciously.',
		title7: 'Security and Privacy Statement:',
		desc7_1: 'For personal data protection, please read the Privacy Statement of the service with caution.',
		desc7_2: 'CURDr. APP is operated by Curdoctor InformationServices Corp. In order to maintain personal privacy and support the protection of personal data, the Company wishes to explain to you the purpose, type, scope and manner of the personal data collection, and the rights you earn. If you have any question in relation to the privacy of the Service, Please contact the Company\'s customer service center, and the Company will reply to you as soon as possible.',
		desc7_3: 'Scope of application: The Privacy Statement of the Service and thenotices contained therein apply only to this APP. ThisAPP may include many links, or other partners, including but not limited to third-party payment institions and merchants. Please refer to each linked website, partner, or merchant\'s website for information about the privacy statement and individual protection.',
		desc7_4: 'Purpose and category of personal data collection : For the purposes of providinghealthcare service match making,e-commerce services, after-sales service, fulfilling statutory or contractual obligations, protecting the interests of related parties, marketing, customer management and services, and business in  registration items or articles of association,the Company will collect your name, contact information (including but not limited to telephone, email,address, etc.), incomplete collection or payment information, and other personaldata that directly or indirectly identifies the user\'s body in accordance with the nature of each service. In addition, in order to improve the quality of service, theCompany will record the user\'s IP address, as well as the browsing activities within the App (e.g., the hardware and software used , the selected web page, the downloaded APP). But these data id only used for improvement of the quality of service by traffic analysis and behavior survey. It will not be linked toa specific individual.',
		desc7_5: 'Use of personal data: Personal data collected bythis APP Service is sufficient toidentify the user,including but not limited to commercial registration information, legal representative name, address, identity card number, if a natural person, are personal name, address, ID card number and other relevant information. It is only used for the Company\'s internal processing, and use in accordance with the purposes of collection, and provide to other partners and merchants within a reasonable scope of collection, processing, storage,transmissionanduse of such data for authorization of application of payment, etc. The Company will not provide personal data relating to the identity of the user to third parties, both domestic and oversea, or for use for purposes other than the purpose of the collection, unless stated in advance, or necessary to complete the service or fulfill contractual obligations, or by order or request of the competent authority in accordance with the relevant laws and regulations or the competent authority. The Company will keep, process and use the relevant information for the period according to regulations.',
		desc7_6: 'Data Security: The Company will maintain the security of personal data in reasonable technology and procedures with industry standards.',
		desc7_7: 'The rights of the data party : the data party may request inquiries or browsing ofthe party’s data. The Company may charge the necessary fees for the costs required for processing. If your personal data has changed or you find that your data is incorrect, you may request a change or correction from the Company. When the purpose of collecting personal data disappears or the period expires, itmay be requested to delete, stop processing or using the personal data. However, it is not include those required for the Company\'s business practice.',
		desc7_8: 'Cookies: For the convenience of the user, the Company\'s website may readcookies stored in the user\'s device.If you would like to know how to cancel or limit this feature, please contact our customer service center. ',
		desc7_9: 'Impact: If you do not wish to provide the relevant personal data required for the services or transactions to the Company, and agree to the Company follows legal requirement, the privacy statement, and its related notices for the collection, processing, utilization and international transmission of the relevant personal data. the Company will respect your decision, but in accordance with the nature or conditions of each service, you may not be able to use such services or complete the relevant transactions. The Company reserves the right if to provide such such services or complete the relevant transactions.',
		desc7_10: 'Right of amendment : The Company reserves the right to revise this Privacy Statement and related notices at any time, and post a notice in the Company\'s website and APP after the change and revision, without personal notice You may at any time check the revised privacy statement and related notices in the Company\'s website and APP.',
    },
    emr: {
        male: 'Male',
        female: 'Female',
		agentAppt: 'Make an Appt.',
        contactAgent: 'Contact caregiver',
        transfer: 'Transfer',
        done: 'Done',
        unfinish: 'Unfinished',
        finish: 'Finished',
        exit: 'Exit',
        backToAppt: 'Back to the appointment',
        exitTip: '{name}\'s consultation is finished？',
        transferTip: 'Transferring in process, please wait...',
        returnToCallingQueue:'Return the calling queue',
        appt: {
            patientNotes: 'Remarks',
            subjective: 'Subjective Information',
            objective: 'Objective Information',
            ICD10: 'Accessment by ICD 10 ',
            medicalPlan: 'Medical Plan',
            suggestion: 'Consulting Records',
            enterMedicalPlan: 'ICD 10 keywords',
            enterMedicalCode: 'Medicine keywords',
            importMedicalPack: 'Import by medicine pack keyword',
            exportMedicalPack: 'Export medicine pack',
            apptHistory: 'Past Appointments',
            ICD10NoData: 'Accessment N/A',
            copyObjective: 'Copy to current note',
            copyICD10: 'Copy to current note',
            copyMedicalPlan: 'Copy to current note',
            copySuggestion: 'Copy to current note',
            copyToCurrentNote: 'Copy to current note',
            code_ICD: 'Code',
            name_ICD: 'Name',
            remarks_ICD: 'Remarks',
            code_p: 'Code',
            name_p: 'Name',
            unit_p: 'Unit',
            dose_p: 'Dose',
            frequency_p: 'Frequency',
            route_p: 'Route',
            days_p: 'Days',
            total_p: 'Total',
            daily_p: 'Daily',
            daily:'Daily',
            frequency:'Frequen',
            notes_p: 'Notes',
            backToAppt: 'Back to the appointment',
		    personalSickHistory: 'Personal Medical History',
            allergy: 'Allergy',
            date: 'Date',
            interval: 'Period',
            service: 'Requirement',
            saving: 'Saving…',
            saved: 'Saved',
            continuousPrescription: 'Refill Prescriptions',
            prescription:'Prescription',
            checkPrescription:'check Prescription',
            phone:'phone :',
            mr_id:'mr_id :',
            name:'name :',
            sex:'sex :',
            birth:'birth :',
            did:'did :',
            dname:'dname :',
            date:'date :',
            number:'number',
            CaseType:'CaseType',
            division:'division',
            am: 'Morning',
            pm: 'Afternoon',
            night: 'Night',
            saveRx: 'Save Formulary',
            rxName: 'Formulary Name',
            rxCode: 'Formulary Code',
            rxContent: 'Formulary Content',
            duplicateName: 'The name has existed.',
            duplicateCode: 'The code has existed.',
            cart_error: "Please select the recommend product and send out",
            RecommendProduct:"Recommend Product",
            goProductShop:"Go To Shop",
            AddRecommend:"Add Recommend",
            ViewProductimg:"View Product image",
            SendRecommend:"Send",
            Recommend:"Recommend",
            ProductName:"Product Name",
            ProductNumber:"Quantity",
            Price :"Unit Price",
            Description:"Description",
            Image:"Image",
            DataProcessing:"Data Processing",
            CheckSend:"Please confirm that you want to send the recommended product, you will not be able to modify it after delivery",
            Manufacturer:"Manufacturer",
            SearchRecommend:"Search Product",
            ChooseProduct:"Choose Products",
            SetProduct:"Set Products",
            ChooseProductError:"Please search products in the recommended Product first",
            UseMyProduct:"Use My Products",
            SetRecommProduct:"Please set recommend products first",
            UseProduct:"Use",
            MyRecommend:"My Recommend Products",
            PatientName:"Patient Name"
        },
        healthdata: {
            "afib": "Atrial Fibrillation",
            "arr": "Arrhythmia",
            "pc": "Premature Contraction",
            "ihb": "Irregular Heartbeat",
        },
        healthreport: {
            checkup_title:"Check Up Report"
        }
    },
    apptHistory: {
        prev: 'Back',
        title: 'Appointment Records',
        downloadReport: 'Dowload',
        filter: 'Filter',
        medicalInstitution: 'Medical Institution',
        searchPatient: 'Input name, phone number or Personal ID',
        all: 'All',
        week: 'Week',
        month: 'Month',
        sevenDays: '7 Days',
        thirtyDays: '30 Days',
        startDate: 'Start Date',
        endDate: 'End Date',
        site: 'Consultation via (All)',
        videoChat: 'Video Chat',
        onsiteVisit: 'Onsite visit',
        status: 'Appt. Status (All)',
        validQueue: 'Valid Appts.',
        unfinish: 'Unfinished',
        finish: 'Finished',
        cancel: 'Cancelled (All)',
        patientCancel: 'by Patients',
        systemCancel: 'by System',
        type: 'Appt. Types (All)',
        generalAppt: 'General Appts.',
        emergencyAppt: 'Emergencies',
        specAppt: 'Special Appts.',
        proxy: 'Appts made by (All)',
        patientProxy: 'by Patients',
        doctorProxy: 'by Medical Staffs',
        showVideo: 'Appts. with Videos',
        limitPerPage: 'Page Size',
        limit: '{num} records',
        mr: 'Medical Record',
        am: 'AM',
        pm: 'PM',
        night: 'Night',
        male: 'Male',
        female: 'Female',
        recordFile: 'Video Files',
        referAppt: 'Appt. made by {name}',
        service: 'Service Items: ',
        suggestion: 'Suggestions: ',
        noData: 'No data',
    },
    stream: {
        inviteBtn: '邀请民众家属加入',
        inviteTitle: '选择邀请对象',
        invite: '邀请',
        notify_title: '{dname} invites you to a video chat',
        notify_content: '{dname} is having a video chat with {pname}. <a class="item-link external" href="*url*" target="_blank">Click here</a> to join.',
        msg: '{dname} is having a video chat with {pname}. Click the link to join. *url*',
    },
    // 元件
    components: {
        msg: {
            msg: 'Message',
            setWord: 'Can Words',
            apply: 'Apply',
            noSetWord: 'No Can Words available,please go to  Settings > Can Words to create them',
            createNow: 'Create Now',
            content: 'Content',
            send: 'Send',
        },
        call: {
            call: 'Call',
            useMic: 'Please allow microphone and camera',
            patientOffline: 'Patient is not online now',
            doctorOffline: 'Doctor is not online now',
            offline: 'Offline',
            busy: 'Busy',
            leave: 'Left',
            medicalStaff: 'The medical staffs in the member\'s unit(s)',
            patient: 'Member',
        },
        agent:{
            videoChat: 'Video Chat',
            onsiteVisit: 'Onsite visit',
            selectedDoctor: 'Doctor',
            callTo: 'Whom to call',
            problem: 'Request',
            fee: 'Fees',
            peopleNum: ' people',
            doctorLeave: 'Doctor is off.',
            appointment: 'Appointment',
            timeOut: 'Time Out',
            am: 'AM',
            pm: 'PM',
            night: 'Night',
            confirmText_1: 'Please confirm the following information',
            confirmText_2: 'Appointments Doctor: ',
            confirmText_3: 'Patient Name: ',
            confirmText_4: 'Payment plan: ',
            confirmText_5: 'Reservation Time: ',
            confirmText_6: 'day, Period: ',
            confirmText_7: 'Notice：Please remind the patient to complete the payment within 30 minutes。',
            doctorNoService: 'The doctor doesn\'t offer service, please find another doctor.',
            partner: 'Medical Institution',
        },
        specAgent:{
            specAppt: 'Special Appt.',
            partner: 'Medical Institution',
            doctor: 'Doctor',
            selectDoctorMember: 'Select a doctor',
            myself: '(Myself)',
            myContact: 'My Contacts',
            whosDoctor: '{partnerName}\'s doctors',
            date: 'Date',
            selectDate: 'Select a date',
            totalTime: 'Consultation time in minutes',
            enterMinutes: 'Input consultation time',
            patient: 'Patient',
            patientTip: 'Please select an Patient Member ID or Patient Name.',
            selectPatientMember: 'Select a patient',
            time: 'Time',
            timeTip: 'Both parties will be reminded 30 minutes before the selected time.',
            selectTime: 'Select a time',
            amount: 'Amount',
            enterAmount: 'Please enter amount',
            vipMember: 'VIP Member',
            newSpecAppt: 'New Special Appt.',
            communicationType: 'Communication type',
            videoChat: 'Video Chat',
            onsiteVisit: 'Onsite visit',
        },
        apptBtn:{
            apptBtn: 'Make an Appt.',
            appt: 'General Appt.',
            specAppt: 'Special Appt.',
            switch: 'switch',
            videoChat: 'Video Chat',
            onsiteVisit: 'Onsite visit',
            patient: 'Patient',
            patientTip: 'Select a Patient (ID or Name)',
            doctor: 'Doctor',
            myself: '(Myself)',
            myContact: 'My Contacts',
            whosDoctor: '{partnerName}\'s doctors',
            callBack: 'Patient\'s Agent. (Receive the call)',
            fee: 'Fees',
            problem: 'Service Request',
            dateTimeSetting: 'Datetime settings',
            peopleNum: ' people',
            doctorLeave: 'Doctor is off.',
            appointment: 'Appointment',
            timeOut: 'Time Out',
            time: 'Time',
            timeTip: 'Doctor and patient will be reminded 30 mins in advance',
            totalTime: 'Consultation time in minutes',
            patientInPerson: 'The Patient',
            inPerson: 'Me',
            am: 'AM',
            pm: 'PM',
            night: 'Night',
            minutes: 'mins',
            confirmText_1: 'Please confirm the following information',
            confirmText_2: 'Appointments Doctor: ',
            confirmText_3: 'Patient Name: ',
            confirmText_4: 'Payment plan: ',
            confirmText_5: 'Consultation time in minutes: ',
            confirmText_6: 'Consultation Date: ',
            confirmText_7: 'Consultation Interval: ',
            confirmText_8: 'Consultation Time: ',
            confirmText_9: 'Attention: Please remind the patient to complete the payment within 30 minutes.',
            errorMsg1: 'Please select a patient.',
            errorMsg2: 'Please select a doctor.',
            errorMsg3: 'Please select a date.',
            errorMsg4: 'Please input a doctor fee.',
            errorMsg5: 'Please select a time.',
            errorMsg6: 'Please input the consultation time in munites.',
            selectCsDoc: 'Doctor for consulation',
            consultation: 'Consultation',
        },
        upload: {
            title: 'Medical Files Upload',
            patient: 'Patient',
            accountTip: 'Please select a patient Account',
            patientTip: 'Select a Patient (ID or Name)',
            uploadType: 'File category',
            notes: 'Remarks',
            selectFile: 'Select a file',
            upload: 'Upload',
            feeBack: {
                noPatient: 'Member is required',
                noType: 'Category is required',
                noFile: 'File is required',
                upload_success: 'Upload successfully',
            },
            type: {
                generalPhoto: 'Photograph',
                generalReport: 'PE Report',
                dicom: 'DICOM',
                funduscopy_R: 'Funduscope (Right Eye)',
                funduscopy_L: 'Funduscope (Left Eye)',
                ITRI_AI: 'ITRI Funduscope AI',
                ultrasonograph: 'Ultrasonograph',
                ecg: 'ECG',
                ear: 'Face diagnostic scope - ear',
                nasal: 'Face diagnostic scope - nasal',
                throat: 'Face diagnostic scope - throat',
                oral: 'Face diagnostic scope - oral',
                skin: 'Face diagnostic scope - skin',
                phono: 'Phono',
                video: 'Video',
            },
            accept: {
                0:'Allow .pdf or .jpg, .png and other image files',
                1:'Allow .pdf or .jpg, .png and other image files',
                2:'Only .dcm files are allowed',
                3:'Allow .pdf or .jpg, .png and other image files',
                4:'Allow .pdf or .jpg, .png and other image files',
                5:'Allow .pdf or .jpg, .png and other image files',
                6:'Allow .pdf or .jpg, .png and other image files',
                7:'Allow .pdf or .jpg, .png and other image files',
                8:'Allow .pdf or .jpg, .png and other image files',
                9:'Allow .pdf or .jpg, .png and other image files',
                10:'Allow .pdf or .jpg, .png and other image files',
                11:'Only video files allowed',
            }
        },
        sendMsg: {
            title: '{name}\'s Messages',
            retractMsg: '{name} had unsent the message',
            msgIsSeen: 'Read',
            send: 'Send',
            retractTip: 'Are you sure to unsend the message?',
        },
        selectPartner: {
            choosePartner: 'Please select an institution',
            today: 'Today: ',
            all: 'All: ',
        }
    },
    // 醫事人員類別
    staffType: {
        Doc: 'Doctor',
        Nur: 'Nurse',
        Pha: 'Pharmacist',
        Cus: 'Customer Service',
        Care: 'Care Worker',
        Dietitian: 'Dietitian',
        Psy: 'Consultant Psychologist',
        Clinical: 'Clinical Psychologist',
        Phy: 'Physical therapist',
        Func: 'Functional therapist',
        Heal: 'Health manager',
        Radio: 'Medical radiologist',
        Exam: 'Medical examiner',
        Case: 'Case management',
        HSec: 'Health secretary',
        Vol: 'Volunteer',
    },
    // 合作夥伴
    partner: {
        KeelungChangGungMemorialHospital: 'Keelung Chang Gung Memorial Hospital',
        AllianceTitle: 'Wiselove',
        ChengHsinTitle: 'Cheng-Hsin Hospital',
        CLCTitle: 'CLC CARE',
        PingChrisTitle:'ptch hospital',
        CamiansaintTitle: 'Camilians Saint Mary\'s Hospital Loudong',
        CurdrTitle: 'CURDr.',
    },
    // element UI 元件
    el: {
        colorpicker: {
            confirm: 'OK',
            clear: 'Clear'
        },
        datepicker: {
            now: 'Now',
            today: 'Today',
            cancel: 'Cancel',
            clear: 'Clear',
            confirm: 'OK',
            selectDate: 'Select date',
            selectTime: 'Select time',
            selectDateTime:'Select date and time',
            startDate: 'Start Date',
            startTime: 'Start Time',
            endDate: 'End Date',
            endTime: 'End Time',
            prevYear: 'Previous Year',
            nextYear: 'Next Year',
            prevMonth: 'Previous Month',
            nextMonth: 'Next Month',
            year: '',
            month1: 'January',
            month2: 'February',
            month3: 'March',
            month4: 'April',
            month5: 'May',
            month6: 'June',
            month7: 'July',
            month8: 'August',
            month9: 'September',
            month10: 'October',
            month11: 'November',
            month12: 'December',
            week: 'week',
            weeks: {
                sun: 'Sun',
                mon: 'Mon',
                tue: 'Tue',
                wed: 'Wed',
                thu: 'Thu',
                fri: 'Fri',
                sat: 'Sat'
            },
            months: {
                jan: 'Jan',
                feb: 'Feb',
                mar: 'Mar',
                apr: 'Apr',
                may: 'May',
                jun: 'Jun',
                jul: 'Jul',
                aug: 'Aug',
                sep: 'Sep',
                oct: 'Oct',
                nov: 'Nov',
                dec: 'Dec'
            }
        },
        select: {
            loading: 'Loading',
            noMatch: 'No matching data',
            noData: 'No data',
            placeholder: 'Select'
        },
        cascader: {
            noMatch: 'No matching data',
            loading: 'Loading',
            placeholder: 'Select',
            noData: 'No data'
        },
        pagination: {
            goto: 'Go to',
            pagesize: '/page',
            total: 'Total {total}',
            pageClassifier: ''
        },
        messagebox: {
            title: 'Message',
            confirm: 'OK',
            cancel: 'Cancel',
            error: 'Illegal input'
        },
        upload: {
            deleteTip: 'press delete to remove',
            delete: 'Delete',
            preview: 'Preview',
            continue: 'Continue'
        },
        table: {
            emptyText: 'No Data',
            confirmFilter: 'Confirm',
            resetFilter: 'Reset',
            clearFilter: 'All',
            sumText: 'Sum'
        },
        tree: {
            emptyText: 'No Data'
        },
        transfer: {
            noMatch: 'No matching data',
            noData: 'No data',
            titles: ['List 1', 'List 2'], // to be translated
            filterPlaceholder: 'Enter keyword', // to be translated
            noCheckedFormat: '{total} items', // to be translated
            hasCheckedFormat: '{checked}/{total} checked' // to be translated
        },
        image: {
            error: 'FAILED'
        },
        pageHeader: {
            title: 'Back' // to be translated
        },
        popconfirm: {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }
    },
    // 系統訊息
    message: {
        data_not_input: 'No data entered',
        upload_success: 'Upload successful',
        upload_error: 'Upload failed',
        upload_file_error: 'Upload failed. Please check file size and format.',
        data_apdate_success: 'Data update successful.',
        search_full_message: 'Please enter the complete ID, Phone or Residence card number in the search box.',
        search_full_name_account: 'Please enter the complete name or account.',
        confirm_delete_record: 'Confirm delete one record',
        send_success: 'Transmission successful.',
        send_error: 'Transmission failed.',
        delete_success: 'Delete successful',
        delete_error: 'Delete failed.',
        register_again: 'Please register again.',
        register_success: 'Registration successful.',
        register_error: 'Registration failed.',
        register_finish: 'Complete consultation',
        checkField: 'Please Check Field!',
        update_success: 'Update successful',
        update_error: 'Update failed.',
        create_success: 'Add successful',
        create_error: 'Add failed.',
        save_success: 'Save successful',
        save_error: 'Save failed',
        invite_success: 'Invite successful',
        invite_error: 'Invite failed',
        copy_success: 'Copied successful',
        copy_error: 'Copied failed',
        cart_error: "Please select the recommend product and send out"
        
    },
    //飲食管理
    dietmgmt:{
        dietmgmt:'Diet Management',
        Height: 'Height (CM)',
        Weight: 'Weight (KG)',
        NoData:'No Data',
        Analyze:'Analyze',
        MealDate:'Meal Date',
        MealDescription:'Description',
        EstimatedKCals:'Estimated KCals by User',
        FoodName:'Food Name',
        Kcals:'Calorie',
        ApplyMealDescription:'Apply MealDescription',
        ApplyEstimatedKCals:'Apply EstimatedKCals',
        Suggest:'Suggest',
        DietAnalyze:'Diet Analyze',
        NextPhoto:'Next photo',
        Carbohydrate:'Carbohydrate',
        Protein:'Protein',
        Fat:'Fat',
        Water:'Water',
        Gram:'grams',
        Ml:'ml',
        Status:'Status',
        WholeMilk: "Whole Milk",
        LowMilk: "Low-Fat Milk",
        SkimMilk: "Skim Milk",
        Fruit: "Fruit",
        Vegetables: "Vegetables",
        Staple: "Staple",
        HighFatMeat: "High-Fat Meat",
        MediumFatMeat: "Medium-Fat Meat",
        LowFatMeat: "Low-Fat Meat",
        MediumLegumes: "Medium-fat Legumes",
        LowLegumes: "Low-fat Legumes",
        Grease: "Grease",
        Quantity:"Quantity",
        TodaysCarbohydrate: "Today's Carbohydrate",
        TodaysProtein: "Today's Protein",
        TodaysFat: "Today's Fat",
        TodaysCal: "Today's Cal",
        Unit:"unit",
        Units:"unit",
        analyze:"Analyze",
        DietInformation:"Diet Information",
        MealTime:"MealTime",
        MealDescription:"Meal Description :",
        kcal:"kcals",
        Save:"Save",
        Calorie:"Calorie",
        UpdateRecomm:"Update Recommend",
        FirstMeal:"First Meal",
        SecondMeal:"Second Meal",
        ThirdMeal:"Third Meal",
        ForthMeal:"Forth Meal",
        FifthMeal:"Fifth Meal",
        SixthMeal:"Sixth Meal",
        Recomm:"Recommend",
        notSetRecomm:"Recommend is not set",
        Save:"Save",
        noData:"No Data",
        Analyzed:"Analyzed",
        call:'call  '
    },
    Question:{
        questionmgmt:"Question Management",
        writer:"writer",
        answer_member:"View reply"
    },
    // 常用彈出視窗元件
    popup: {
        alert: 'Alert',
        confirm: 'Confirm',
        cancel: 'Cancel',
        add: 'Add',
        save: 'Save',
        update: 'Update',
        edit: 'Edit',
        reset: 'Reset',
    },
    freeclinic:{
        freeclinicEventList: 'No free clinic events are open, nor you have participated any event yet',
    }
}