import { setProduct } from '../api/user'

export default {
    main: {
        repeatLogin: '已在其他裝置登入',
        connectionFailed: '網路連線錯誤',
        recieveNewMyTodoList: '收到新的待辦事項',
        todoListDone: '有完成的交辦事項',
        shiftChangeNotice: '班表變更通知',
    },
    login: {
        login: '登入',
        id: '身分證',
        phone: '電話',
        account: '帳號',
        password: '密碼',
        passwordConfirm: '再次輸入密碼',
        rememberAccount: '記住帳號',
        forgetPassword: '忘記密碼',
        register: '註冊',
        registerpw: '密碼 至少6位英數混合',
        choosePartner: '請選擇醫事機構',
        failedAlert1: '登入金鑰有狀況,請與系統管理員聯繫!',
        failedAlert2: '請輸入正確資料',
        failedAlert3: '密碼錯誤',
        failedAlert4: '找不到使用者',
        failedAlert5: '此帳號已關閉',
        failedAlert6: '密碼輸入錯誤超過4次，請等待15分鐘，在嘗試登入。',
        loginFailed: '登入失敗',
        mobile: '手機號碼',
        back: '返回登入',
        submit: '送出',
        sendVerifyCode: '驗證碼已發送至手機',
        firstName: '姓',
        lastName: '名',
        mobileVerifyCode: '手機驗證碼',
        mobileVerify: '手機驗證',
        timeLeft: '剩餘',
        seconds: '秒',
        agree: '我同意',
        selectType: '選擇功能權限',
        selectTitle: '選擇稱謂',
        deliverFailed: '驗證碼發送失敗',
        deliverSuccessful: '驗證碼已發送至手機',
        privacy: '註冊合約',
        confirmLogin: '請聯絡客服進行帳號開通',
    },
    navbar: {
        doctorsStatus: '合作醫師',
        offline: '暫離',
        updateInfo: '更新資訊',
        reservation: '掛號管理',
        chat: '聊天室',
        onlineStatus: '待診',
        busyStatus: '忙碌',
        offlineStatus: '離開',
        profile: '個人資訊',
        notify: '通知',
        changePartner: '切換機構',
        logout: '登出',
        newVersionReload: '後台主機系統已更新，請立即重新整理視窗已確保資料正確性',
        later: '稍後',
        reload: '重新整理',
        closeReload: '是否關閉重新整理視窗?',
        choosePartnerGroup: '請選擇醫事機構',
        Online: '在線',
        Offline: '離線',
    },
    home: {
        todayAppointment: '今日掛號',
        futureAppointment: '未來掛號',
        chatRoomMsg: '聊天室訊息',
        toDoList: '待辦事項',
        todayGeneralIdea: '本日概況',
        weekGeneralIdea: '本週概況',
        monthGeneralIdea: '本月概況',
        todayAppointmentUint: '診',
        futureAppointmentUint: '診',
        chatRoomMsgUint: '則',
        toDoUint: '項',
        reservationTotal: '預約人數',
        checkInTotal: '報到人數',
        finishTotal: '完成人數',
        peopleTotal: '人數',
        Mon: '週一',
        Tue: '週二',
        Wed: '週三',
        Thu: '週四',
        Fri: '週五',
        Sat: '週六',
        Sun: '週日',
        resetPasswordOrNot: '是否前往重新設定新密碼',
        doctorJoinMsg: '歡迎加入{partnerName}，請完成個人資料的填寫，並等待專員審核上線，謝謝您!',
        doctorPhoneVerifyMsg: '歡迎加入{partnerName}，請完成手機號碼驗證，謝謝您!',
    },
    reservation: {
        am: '上午',
        pm: '下午',
        night: '晚診',
        other: '其他時段',
        emergency: '急診紀錄',
        agent: '代理掛號',
        special: '特約掛號',
        record: '掛號紀錄查詢',
        otherRWD: '其他',
        emergencyRWD: '急診',
        agentRWD: '代掛',
        specialRWD: '特診',
        recordRWD: '紀錄',
        card: '卡片',
        table: '列表',
        doctor: '醫師',
        status: '狀態',
        all: '全部',
        unfinish: '未完診',
        finish: '已完診',
        cancel: '已取消掛號',
        cancelSystem: '逾時自動取消掛號',
        specialReservation: '新增特約掛號',
        selectDoctorMember: '請選擇醫師會員',
        selectPatientMember: '篩選符合民眾',
        selectDate: '請選擇日期',
        selectTime: '請選擇時間',
        startDate: '開始日期',
        endDate: '結束日期',
        enterMinutes: '輸入諮詢分鐘',
        enterPrice: '請輸入金額',
        tip1: '請輸入民眾姓名或身分證字號',
        tip2: '將於選定時間前三十分鐘提醒雙方',
        vipMember: 'VIP會員',
        search: '搜尋',
        noData: '目前無掛號資訊',
        confirmText_1: '請確定以下資訊：',
        confirmText_2: '預約醫師：',
        confirmText_3: '民眾大名：',
        confirmText_4: '付費方案：',
        confirmText_5: '分鐘',
        confirmText_6: '預約時間：',
        confirmText_7: '時段：',
        confirmText_8: '注意：請提醒民眾在30分鐘內,付完款項。 ',
        sms: '發會議連結',
        unSay: '未說明',
        minutesPlan: '分鐘方案',
        medicalRecord: '病歷',
        contactAgent: '聯繫代理人',
        minutes: '分',
        seconds: '秒',
        weChatCall: '微信通話',
        healthlinkCall: '遠盟通話',
        lineMsg: 'LINE 訊息',
        smsText: '您將發送會議連結，請民眾進入診間。民眾進入診間後，您也會收到提醒通知。',
        smsTextCancel: '會員錯過通話服務，掛號已取消。',
        man: '男',
        woman: '女',
        downloadReport: '下載掛號名單',
        pdfDownload: 'PDF下載',
        excelDownload: 'Excel下載',
        patientList: '民眾名單列表',
        invite: '邀請',
        hasInvited: '已呼叫',
        notInRoom: '民眾未進入診間',
        isWaitting: '民眾已進入診間等候',
        EnterRoom: '看診',
        times: '次',
        hasWaited: '已等候',
        copyLink: '複製視訊連結',
        copyLinkNotify: '已複製視訊連結至剪貼簿',
        site: '[到院診]',
        switchNewVersion: '體驗新版',
        Inroom: '已進入診間',
        patient: '民眾',
        familyMembers: '的家屬',
    },
    apptMgmt: {
        otherInstitution: '其他機構掛號',
        my: '我',
        myself: '我',
        whosAppt: '{name}的掛號',
        myReferralAppt: '我代理的掛號',
        authDoc: '授權我的醫師',
        switchOldVersion: '切回舊版',
        agent: '代理掛號',
        special: '特約掛號',
        filter: '篩選',
        todayType: '今日掛號',
        futureType: '未來掛號',
        today: '今日掛號',
        future: '未來掛號',
        am: '上午診',
        pm: '下午診',
        night: '晚上診',
        spec: '特約門診',
        emergency: '急診',
        status: '掛號狀態(全部)',
        unfinish: '未完診',
        finish: '已完診',
        cancel: '已取消',
        cancelSystem: '逾時自動取消掛號',
        call: '通話',
        invite: '邀請',
        enterRoom: '看診',
        goToMr: '進入病歷',
        sendMsg: '發送訊息',
        copyLink: '複製簡訊連結',
        notInRoom: '民眾未進入診間',
        isWaitting: '民眾已進入診間等候',
        tag: '{name}代掛',
        tag2: '回撥給{name}',
        noData: '目前查無資料',
        shortSpec: '特約',
        onsiteVisit: '到院診',
        freeClinicUntested: '未完成{num}項',
        freeClinicTested: '已完成{num}項',
        callBack: '通話對象：',
        csTags: '與{dname}醫師的會診',
        consultation: '會診掛號',
    },
    // 諮詢紀錄（舊）目前沒用
    advisory: {
        filter: '過濾',
        today: '今日',
        week: '本週',
        month: '本月',
        season: '本季',
        year: '本年',
        search: '搜尋',
        searchNameAndPhone: '搜尋姓名或電話',
        searchNameAndPhoneAndAccount: '搜尋姓名、電話或帳號',
        startDate: '開始日期',
        endDate: '結束日期',
        agent: '代理掛號',
        emr: '檢視病歷',
        video: '檢視影像',
        seconds: '秒',
        quarterDefinition: '＊本季說明：ㄧ季定義為三個月分別 1-3 ｜ 4-6 ｜ 7-9 ｜ 10-12',
        nodata: '目前查無資料',
        recordFile: '錄影檔案',
    },
    todolist: {
        todolist: '待辦事項',
        ask: '交辦',
        process: '進行中',
        finish: '完成',
        nodata: '目前查無資料',
        toAsk: '交辦事項',
        sendTo: '傳送對象',
        content: '內容',
    },
    chat: {
        searchContact: '搜尋聯絡人',
        patient: '民眾',
        noContact: '請前往會員管理點選訊息開始聊天',
        noMore: '沒有更多',
        loading: '加載中',
        medicalStaff: '醫事人員',
        addMRStaff: '新增醫事人員',
        searchMRStaff: '搜尋醫事人員',
        noMRStaff: '目前無醫事人員',
        group: '群組',
        groupName: '群組名稱',
        addGroup: '新增群組',
        noGroup: '目前無群組',
        sendMsg: '發送訊息',
        submit: '送出',
        enterGroupName: '請輸入群組名稱',
        selectGroupMember: '請選擇群組會員',
        reselectUser: '請重新選擇使用者',
        selectUser: '請選擇使用者',
        excelExport: 'Excel匯出',
        msgIsSeen: '已讀',
        msgIsRecycle: '點擊收回此則訊息',
        recycleMsg: '已收回此筆資料',
        alertRecycleMsg: '收回此則訊息將無法復原!是否確認收回此筆資料？',
    },
    notify: {
        title: '通知',
        nodata: '無通知紀錄',
    },
    member: {
        totalRecord: '筆',
        pleaseSelect: '請選擇',
        selectGroup: '請選擇組別',
        all: '全部',
        searchNameAndAccount: '搜尋姓名或帳號',
        search: '搜尋',
        card: '卡片',
        table: '列表',
        patientNoAuth: '病歷未授權',
        personalSick: '個人病史',
        none: '無個人病史',
        patient: '民眾',
        transfer: '轉接醫師',
        medicalRecord: '病歷',
        upload: '上傳',
        agent: '代理掛號',
        pleaseClick: '請按',
        noData: '目前查無資料',
    },
    membersMgmt: {
        prev: '上一頁',
        title: '會員管理',
        filter: '篩選',
        unit: '全部單位',
        tags: '案件類別不拘',
        disease: '個人病史不拘',
        dataLength: '筆',
        male: '男',
        female: '女',
        noAuth: '病患未授權',
        noDisease: '無個人病史',
        goToMr: '病歷',
        call: '通話',
        sendMsg: '訊息',
        agent: '代理掛號',
        special: '特約掛號',
        upload: '上傳醫療影像',
        addCase: '新增服務案件',
        noData: '查無資料',
        callTitle: '選擇通話對象',
    },
    monitor: {
        title: '遠距監看',
        refresh: '刷新',
        autoRefresh: '自動刷新',
        selectUnit: '請選擇單位',
        all: '全部',
        allDays: '所有的量測',
        oneDay: '1天內量測',
        threeDays: '3天內量測',
        sevenDays: '7天內量測',
        thirtyDays: '30天內量測',
        temperature: '體溫',
        weight: '體重',
        pulse_rate: '心跳',
        blood_oxygen: '血氧',
        blood_sugar_before: '血糖(飯前)',
        blood_sugar_after: '血糖(飯後)',
        blood_sugar_empty: '血糖(空腹)',
        systolic_blood_pressure: '血壓(收縮壓)',
        diastolic_blood_pressure: '血壓(舒張壓)',
        pulse_rate_bpm: '心律(血壓機)',
        electrocardiography: '心電圖',
        noData: '查無資料',
        noHealthData: '沒有數據',
        man: '男',
        woman: '女',
        min: '最小值',
        max: '最大值',
        monitorNoAuth: '未開啟遠距監看功能',
        noMonitorGroup: '沒有監看群組，請合作夥伴管理者至合作夥伴後台建立監控群組',
        prev: '回上一頁',
    },
    opinion: {
        opinion: '文檔會診紀錄',
        beInvited: '被邀請會診項目',
        invite: '邀請會診項目',
        beInvitedRWD: '被邀請',
        inviteRWD: '邀請',
        startDate: '開始日期',
        endDate: '結束日期',
        detail: '檢視內容',
        process: '進行中',
        finish: '完成',
        noData: '目前查無資料',
        content: '內容',
        mr: '病歷',
        generalPhoto: '患部照片',
        generalReport: '健檢報告',
        dicom: 'DICOM',
        funduscopy: '眼底鏡圖片',
        ITRI_AI: '工研院眼底鏡AI',
        ultrasonograph: '超音波照片',
        ecg: '心電圖',
        faceDiagnosticScope: '五官鏡',
        ear: '耳道',
        nasal: '鼻腔',
        throat: '咽喉',
        oral: '口腔',
        skin: '皮膚',
        phono: '心電心音',
        video: '一般影片',
    },
    crm: {
        pageTitle: 'CRM 案件',
        addCase: '新增案件',
        addNotes: '新增註記',
        caseNotes: '案件註記',
        tracking: '追蹤',
        track: '待追蹤',
        today: '今天',
        tomorrow: '明天',
        days: '{days}天後',
        daysAgo: '{days}天前',
        assign: '交辦',
        assignTo: '{from_name} 已交辦給 {to_name}',
        caseAssign: '案件交辦',
        createdBy: '{name}建立',
        noData: '目前查無資料',
        patient: '民眾',
        doctor: '負責人',
        caseTitle: '案件標題',
        caseType: '案件類型',
        notes: '註記',
        finish: '結案',
        finished: '已結案',
        cancel: '已取消',
        commentHistory: '歷史紀錄',
        selectCaseOwner: '選擇案件負責人',
        sec: '{num}秒前',
        min: '{num}分鐘前',
        hour: '{num}小時前',
        day: '{num}天前',
        month: '{num}個月前',
        year: '{num}年前',
        assignMsg: '[系統訊息] {from_name}交辦案件給{to_name}',
        cancelAssignMsg: '[系統訊息] {from_name}取消交辦',
        closeAll: '全部結案',
        sendMsg: '訊息',
        agent: '代理掛號',
        special: '特約掛號',
        confirmAllClose: '確定要全部案件結案？',
        close_success: '結案成功',
        noData: '尚未有服務案件',
        myself: '(自己)',
        unassigned: '未交辦',
        selectCase: '選擇需結案的案件:',
        assignCase: '交辦給我的案件',
        unassignedCase: '未交辦的案件',
        detailFinish: '[系統訊息] {dname}結案',
        closeAll: '全部結案',
        close: '結案',
        confirm: '確定修改',
    },
    schedule: {
        schedule: '預約通知',
        addSchedule: '新增預約',
        setTime: '建立時間',
        sendTime: '預計發送時間',
        fail: '發送失敗',
        finish: '已讀取',
        hasSend: '已發送',
        notSend: '待發送',
        noData: '目前查無資料',
        editSchedule: '編輯預約',
        selectDateTime: '選擇日期時間',
        content: '內容',
        update: '更新',
        patient: '民眾',
        medicalStaff: '醫事人員',
        selectPatient: '請搜尋民眾會員',
        selectDoctor: '請選擇醫師會員',
        Topatient: '發送給民眾:',
        ToStaff: '發送給醫事人員:',
    },
    shift: {
        shift: '班表設定',
        myShift: '個人班表',
        maxAppointment: '每診最大預約人數',
        people: '人',
        fixedSchedule: '固定班表',
        arrangeShift: '休診設定',
        changeTime: '變更時間',
        startTime: '開始時間',
        endTime: '結束時間',
        alertText1: '此次班表更動將會影響',
        alertText2: '診掛號，您將需要自行通知已掛號民眾做取消掛號的動作，是否仍要更動班表？',
        mon: '週一',
        tue: '週二',
        wed: '週三',
        thu: '週四',
        fri: '週五',
        sat: '週六',
        sun: '週日',
        am: '上午',
        pm: '下午',
        night: '晚診',
        amRWD: '上',
        pmRWD: '下',
        nightRWD: '晚',
        shiftNote: '× 只能請假今天以後的日期(不含今天)',
        scheduledOff: '請假',
        cancelDayOff: '取消請假',
        setOffDay: '請假成功',
        shiftSetting: '每週班表設定',
        closed: '全院休診',
    },
    authorization: {
        authorization: '授權管理',
        addAuth: '新增授權',
        manageShift: '管理班表',
        contactPatient: '與掛號民眾通話',
        search: '搜尋',
        delete: '刪除',
        prompt: '授權功能僅開放給醫師決策權限使用',
    },
    contact: {
        title: '合作醫護人員',
        subtitle: '搜尋並勾選合作的醫護人員',
        search: '搜尋',
        addContact: '新增聯絡人',
        existContact: '存在聯絡人',
        totalRecord: '筆',
        noData: '目前查無資料',
        deleteContactAlert: '確定要刪除此聯絡人?',
    },
    setWord: {
        title: '常用字句',
        desc: '常用字句分為：主觀項目(S)、客觀項目(O)、醫事人員建議(memop)以及文字交談(wd)，可建立常用的罐頭語句供點選使用，以節省打字時間。',
        import: '匯入',
        addWord: '新增常用字句',
        noData: '目前查無資料',
        edit: '編輯',
        download: '下載模板',
        uploadFile: '上傳文件',
        previewData: '預覽資料',
        finish: '完成',
        downloadExample: '下載模板範例',
        onlyExcelFile: '只接受excel格式文件',
        dragFile: '將檔案拖曳此處或<em>點擊</em>上傳',
        doctorSOAP: '醫事人員S.O.A.P. 類型',
        noDoctorSOAP: '找不到醫事人員S.O.A.P. 類型',
        prevStep: '上一步',
        nextStep: '下一步',
        importMultiple: '多筆匯入',
        soap_S: '主觀項目',
        soap_O: '客觀項目',
        soap_Memop: '醫事人員建議',
        soap_WD: '文字交談',
        diet: '飲食建議',
        diet_Memop: '飲食建議',
        waring: '請修改常用字句再儲存！',
    },
    profile: {
        title: '個人資訊',
        id: '身分證字號',
        name: '姓名',
        email: '信箱',
        doctorStatus: '審核狀態',
        privileges: '功能權限',
        title: '稱謂',
        mobile: '手機號碼',
        mobileVerify: '手機驗證',
        mobilePass: '已驗證',
        mobileNotPass: '未驗證',
        edit: '編輯',
        resetPassword: '修改密碼',
        serviceUnit: '服務單位',
        serviceType: '服務方式',
        serviceList: '服務列表',
        addServiceList: '新增服務項目',
        add: '新增',
        noData: '目前查無資料',
        lang: '精通語言',
        fee: '諮詢費率',
        dayFee: '台灣白天諮詢費率',
        nightFee: '台灣夜間和急診諮詢費率',
        dayFeeChina: '大陸白天諮詢費率',
        nightFeeChina: '大陸夜間和急診諮詢費率',
        NTs: '新台幣',
        CNs: '人民幣',
        queue: '掛號',
        division: '科別',
        educationExperience: '學經歷',
        bankInfo: '銀行資訊',
        bankName: '銀行名稱',
        bankBranch: '銀行分行',
        bankAddress: '銀行地址',
        bankAccount: '銀行帳號',
        bankAccountName: '銀行戶名',
        bankSwiftCode: '銀行代碼',
        otherInfo: '其他資訊',
        personalPhoto: '個人照片',
        licensePhoto: '執業執照',
        selectFile: '選擇檔案',
        selectFileTip: '檔案大小不超過1000MB',
        firstName: '姓',
        lastName: '名',
        enterText: '請輸入相關字句',
        internalMD: '內科',
        surgical: '外科',
        otherSubjects: '其他科別',
        chineseMD: '中醫',
        education: '學歷',
        experience: '經歷',
        position: '職稱',
        selectDomesticOrForeign: '*選擇本國與國外銀行',
        resetPasswordTip: '變更密碼,會重新登出, 再使用新密碼登入。',
        enterNewPW: '請輸入新密碼',
        enterNewPWAgain: '再輸入一次新密碼',
        resetPWPlaceholder: '新密碼, 至少6位數英數混合',
        changeMobile: '變更手機號碼',
        changeMobileTip: '輸入新手機號碼，取得驗證碼，進行驗證。',
        enterMobile: '請輸入新手機號碼',
        enterVerifyCode: '請輸入驗證碼',
        timeLeft: '剩餘',
        seconds: '秒',
        invalidVerifyCodeTimeLimit: '手機驗證碼有效期限為5分鐘',
        outPatient: '到院看診',
        online: '視訊諮詢',
        mainlandChinaService: '提供大陸地區諮詢',
        volunteerOnline: '為弱勢民眾視訊義診',
        onsite: '實地義診',
        localeBank: '本國銀行',
        foreignBank: '國外銀行',
        inProcess: '審核中',
        onBoard: '已驗證',
        removeServiceUnitComfirm: '此操作將移除目前所屬服務單位',
        removeServiceUnitSuccessful: '服務單位移除成功!',
        deliverVerificationSuccessful: '驗證碼已發送至手機',
        deliverVerificationFailed: '驗證碼發送失敗',
        changePWSuccessful: '變更密碼成功，請使用新密碼重新登入。',
        changePWFailed1: '請輸入至少6位數密碼',
        changePWFailed2: '請輸入相同密碼',
        changePWFailed3: '請輸入英數字混合密碼',
        enterCorrectPhoneNumber: '請輸入正確的手機號碼',
        verificationCodeRequired: '手機驗證碼必填',
        imgOnlyTips: '上傳文件只能是圖片格式',
        sizeLimitTips: '圖片大小不能超過 20MB',
        phoneReqiredTips: '歡迎加入{name}，請完成手機號碼驗證，謝謝您!',
        uploadCheck: '請上傳.jpg或.png檔案格式',
    },
    log: {
        title: '操作紀錄',
        startDate: '開始日期',
        endDate: '結束日期',
        noData: '目前查無資料',
    },
    privacy: {
        mainTitle: '醫護人員註冊合約',
        title1: '警語：',
        desc1: '本人身為醫護人員充分瞭解醫師法第11條及政府主管機關對遠距診察的相關法令和規範，本人並同意遵守之。本人瞭解除了主管機關核准從事遠距醫療的特定地區，民眾及情況外，遠距視訊諮詢可做為會員到醫療院所就醫前和就醫後的輔助諮詢工具，提供會員更即時和方便的保健諮詢服務，以提升醫病關係並恊助建立家庭醫師制度。',
        title2: '平台免責權：',
        desc2: '本人了解並同意貴公司謹提供通訊平台供本人和客戶做咨詢服務，不負責處理任何因此諮詢服務所產生本人與客戶的糾紛，亦不必分攤或給付任何糾紛的賠償。',
        title3: '諮詢過程錄音錄影：',
        desc3: '本人了解並同意貴公司系統在諮詢過程執行全程的錄音或錄影，做為收取諮詢費用之依據參考。',
        title4: '諮詢費及平台服務費：',
        desc4: '本公司將會代醫護人員依其自訂之費率及服務秒數向會員收取諮詢費用，收款須先扣除3%的金流費用後，本公司將收取餘額的30%做為的平台服務費並開給發票，另70%轉帳給醫護人員。若會員不付款，本公司仍會保證支付給醫護人員以確保您們的權益，並將會在次月的10號結算前一個月份的諮詢費用，以銀行轉帳的方式匯款到醫護人員註冊時所登記的銀行帳戶，但銀行將會由其中收取15至30元不等的匯款費用。',
        title5: '客戶個資之使用：',
        desc5: '醫護人員瞭解並同意會員一旦與醫護人員預約做健康諮詢，即代表該會員授權該醫護人員及其所屬醫療院所相關醫護人員查看其健康諮詢相關的個資。並授權該醫護人員視必要性將其健康諮詢相關的個資轉授權給指定之轉診及會診的醫護人員及其所屬醫療院所。但醫護人員不得將會員相關的個資複製，傳送或是銷售給未經會員授權或非必要轉診及會診的其他第三者。',
        title6: '取消醫護人員會員權利：',
        desc6: '本公司為維護服務之品質, 對於不具有醫護人員執照和資格的會員，以及不當或惡意使用本服務之醫護人員，保有單方面取消該會員資格之決定權利。',
        title7: '個資安全及隱私權聲明：',
        desc7_1: '關於本公司對於您個人資料保護，請詳細閱讀本服務的隱私權聲明',
        desc7_2:
            '醫生馬上看為健康聯網資訊服務股份有限公司所經營，為了維護個人隱私權與支持個人資料保護，本公司謹以下列聲明向您說明所收集個人資料之目的，類別，利用範圍及方式，以及您所得行使之權利等事項；如果對於本服務的隱私權聲明，以下相關告知事項，或是與個人資料保護有關之相關事項有任何疑問，可以和本公司客服中心連絡，本公司將盡快回覆說明。',
        desc7_3: '適用範圍：本服務隱私權聲明及其所包含的告知事項，僅適用於服務所擁有及經營的網站。本服務網站可能包括許多連結、或是其他合作夥伴及商家，其有關隱私權聲明及個資保護有關之告知事項，請參閱各該連結網站、合作夥伴或是商家之網站。',
        desc7_4:
            '個人資料蒐集之目的與類別：本公司為了提供電子商務服務、售後服務、履行法定或合約義務、保護當事人及相關利害關係人之權益、行銷、客戶管理與服務、以及和與營業登記項目或組織章程鎖定之業務等目的，依照各服務之性質，有可能會收集您的姓名、聯絡方式（包含但不限於電話、email及地址等）、未完成收款或付款所需之資料、IP地址、所在地點、上傳的病歷、醫療圖像、健康檢查、影音以及其他得以直接或間接辨識使用者身分之個人資料。此外，為提升服務品質，本公司會依照所提供服務之性質，記錄使用者的IP地址、及在本服務相關網站或App內的瀏覽活動（例如，使用者說使用的軟硬體、所點選的網頁、下載的APP）等資料，但是這些資料經工作流量分析和網路行為調查，以便於改善本服務的相關網站的服務品質，不會和特定個人相聯繫。',
        desc7_5:
            '個人資料的利用：服務所蒐集的足以識別使用者身份的個人資料，包含但不限於商業登記資料、法定代理人姓名、地址、身分證字號；若為自然人時，則為個人姓名、地址、身分證字號等相關資料，僅提供本公司於內部依照蒐集之目的進行處理和利用，除非事先說明、或為完成提供服務或履行合約義務之必要、或依照相關法令規定或有權主管機關之命令或要求，否則本公司不會將涉及使用者身分的個人資料提供給第三人，包括境內及境外，或作收集目的之外之使用。在法令所定應保存之期限內，本公司會持續保管、處理及利用相關資料。',
        desc7_6: '資料安全：本公司將以與產業標準之合理技術和程序，維護個人資料之安全。',
        desc7_7: '資料當事人之權利：資料當事人可以請求查詢、閱覧本人的個資或要求給予複製本。若您的個資有變更、或發現您的個資不正確，可以向本公司要求修改或更正。當收集個人資料之目的消失或期限屆滿時，應可要求刪除、停止處理或利用個人資料。但因本公司執行業務所得必須者，不在此限。',
        desc7_8: 'Cookie : 為了便利使用者，本公司網站可能會讀取儲存在使用者電腦中的cookie資料。使用者可經由瀏覽器的設定、取消、或限制此項功能，但可能因此無法使用部分網站功能。若你想知道如何取消、或限制此項功能，請與本公司客服中心連絡。',
        desc7_9:
            '影響：如果您不願提供各項服務或交易所要求的相關個人資料予本公司，並且同意本公司就該等個人資料依法令規定、以及隱私權聲明、及其相關告知內容為相關之個人資料蒐集、處理、利用及國際傳輸，本公司將尊重您的決定，但依照各該服務之性質或條件，您可能因此無法使用該等服務或完成相關交易，本公司並保留是否同意提供該等相關服務或完成相關交易之權利。',
        desc7_10: '修訂之權利：本公司有權隨時修訂本隱私權聲明及相關告知事項，於變更與修訂後公佈在本公司之網站及APP內，不另行個別通知，您可以隨時在公司網站及APP內詳閱修訂後的隱私權聲明及相關告知事項。',
    },
    emr: {
        male: '男',
        female: '女',
        agentAppt: '代掛',
        contactAgent: '聯繫代理人',
        transfer: '轉接醫師',
        done: '完診',
        unfinish: '未完診',
        finish: '已完診',
        exit: '離開',
        backToAppt: '返回本次看診',
        exitTip: '請問{name}的掛號是否已完診？',
        transferTip: '已轉接請稍後',
        returnToCallingQueue: '返回通話中掛號',
        appt: {
            patientNotes: '民眾備註',
            subjective: '民眾主觀自覺徵候',
            objective: '醫療人員客觀診斷評估',
            ICD10: '國際疾病分類碼',
            medicalPlan: '藥囑',
            suggestion: '建議紀錄',
            enterMedicalPlan: '輸入ICD10',
            enterMedicalCode: '輸入藥品代號',
            importMedicalPack: '匯入處方集，輸入代碼',
            exportMedicalPack: '匯出處方籤',
            apptHistory: '看診紀錄',
            ICD10NoData: '國際疾病分類碼未填寫',
            copyObjective: '代入醫師主訴',
            copyICD10: '代入ICD',
            copyMedicalPlan: '代入藥囑',
            copySuggestion: '代入建議紀錄',
            copyToCurrentNote: '套用至本次看診',
            code_ICD: '代號',
            name_ICD: '名稱',
            remarks_ICD: '備註',
            code_p: '代號',
            name_p: '藥品處置名稱',
            unit_p: '單位',
            dose_p: '一次量',
            frequency_p: '用法',
            route_p: '途徑',
            days_p: '天數',
            total_p: '總量',
            daily_p: '日劑量',
            daily: '劑量',
            frequency: '頻次',
            notes_p: '用藥說明',
            personalSickHistory: '個人病史',
            allergy: '過敏事項',
            date: '看診日期',
            interval: '看診時段',
            service: '服務項目',
            saving: '儲存中',
            saved: '已儲存',
            continuousPrescription: '連續處方籤',
            prescription: '處方籤',
            checkPrescription: '查看處方籤',
            phone: '電話',
            mr_id: '病歷號',
            name: '姓名',
            sex: '性別',
            birth: '年齡',
            did: '醫師代號',
            dname: '醫師',
            date: '看診日',
            number: '診號',
            CaseType: '案類',
            division: '科別',
            am: '上午診',
            pm: '下午診',
            night: '晚診',
            saveRx: '儲存處方集',
            rxName: '處方集名稱',
            rxCode: '處方集代碼',
            rxContent: '處方集內容',
            duplicateName: '此名稱重複',
            duplicateCode: '此代碼重複',
            cart_error: '請選擇推薦商品再送出',
            RecommendProduct: '推薦商品',
            goProductShop: '前往商品頁',
            AddRecommend: '搜尋推薦商品',
            ViewProductimg: '檢視圖片',
            SendRecommend: '送出',
            Recommend: '推薦商品',
            ProductName: '商品名稱',
            ProductNumber: '商品數量',
            Price: '商品價格',
            Description: '商品描述',
            Image: '檢視圖片',
            DataProcessing: '資料處理中',
            CheckSend: '確認要送出推薦商品，送出後將無法修改',
            Manufacturer: '供應商',
            SearchRecommend: '搜尋商品',
            ChooseProduct: '選擇常用商品',
            SetProduct: '設成常用商品',
            UseMyProduct: '套用常用商品',
            ChooseProductError: '請在下方推薦商品表單搜尋選取商品',
            SetRecommProduct: '請先新增推薦商品',
            UseProduct: '套用',
            MyRecommend: '歷史推薦商品',
            PatientName: '民眾姓名',
        },
        base: {},
        lifeHealth: {},
        bioData: {},
        dicom: {},
        healthdata: {
            afib: '心房顫動',
            arr: '心律不整',
            pc: '早期收縮',
            ihb: '不規則心跳',
        },
        healthreport: {
            checkup_title: '成人健檢報告',
        },
    },
    apptHistory: {
        prev: '上一頁',
        title: '掛號紀錄',
        downloadReport: '下載掛號名單',
        filter: '篩選',
        medicalInstitution: '醫事機構',
        searchPatient: '搜尋民眾姓名、電話或身分證',
        all: '全部',
        week: '本週',
        month: '本月',
        sevenDays: '7天內',
        thirtyDays: '30天內',
        startDate: '開始日期',
        endDate: '結束日期',
        site: '看診方式(全部)',
        videoChat: '視訊診',
        onsiteVisit: '到院診',
        status: '掛號狀態(全部)',
        validQueue: '有效掛號',
        unfinish: '未完診',
        finish: '已完診',
        cancel: '全部取消',
        patientCancel: '民眾取消',
        systemCancel: '系統取消',
        type: '掛號類型(全部)',
        generalAppt: '一般掛號',
        emergencyAppt: '急診掛號',
        specAppt: '特約掛號',
        proxy: '掛號者(全部)',
        patientProxy: '民眾掛號',
        doctorProxy: '醫事人員掛號',
        showVideo: '顯示有錄影檔的掛號',
        limitPerPage: '每頁顯示筆數',
        limit: '{num}筆',
        mr: '病歷',
        am: '上午診',
        pm: '下午診',
        night: '晚診',
        male: '男',
        female: '女',
        recordFile: '錄影檔案',
        referAppt: '{name}代理掛號',
        service: '服務項目：',
        suggestion: '建議紀錄：',
        noData: '查無資料',
    },
    stream: {
        inviteBtn: '邀請民眾家屬加入',
        inviteTitle: '選擇邀請對象',
        invite: '邀請',
        notify_title: '{name}邀請您加入視訊',
        notify_content: '{dname}正在對{pname}進行關懷視訊，點擊此連結<a class="item-link external" href="*url*" target="_blank"> *url* </a>加入視訊',
        msg: '{dname}正在對{pname}進行關懷視訊，點擊連結加入視訊 *url*',
    },
    // 元件
    components: {
        msg: {
            msg: '訊息',
            setWord: '常用字句',
            apply: '套用',
            noSetWord: '沒有常用字句可使用，請前往系統設定＞常用字句進行建立',
            createNow: '馬上建立',
            content: '內容',
            send: '傳送',
        },
        call: {
            call: '通話',
            useMic: '無法進行通訊，請允許使用麥克風與攝影機?',
            patientOffline: '民眾目前未上線',
            doctorOffline: '醫師目前未上線',
            offline: '離線',
            busy: '忙碌',
            leave: '暫離',
            medicalStaff: '民眾所屬單位醫事人員',
            patient: '民眾',
        },
        agent: {
            videoChat: '視訊看診',
            onsiteVisit: '到院看診',
            selectedDoctor: '掛號醫師',
            callTo: '醫師回撥對象',
            problem: '諮詢問題',
            fee: '費用',
            peopleNum: '人數',
            doctorLeave: '醫師請假',
            appointment: '預約',
            timeOut: '時段已過',
            am: '上午',
            pm: '下午',
            night: '晚診',
            confirmText_1: '請確定以下資訊：',
            confirmText_2: '預約醫師：',
            confirmText_3: '民眾大名：',
            confirmText_4: '付費方案：',
            confirmText_5: '預約時間：',
            confirmText_6: '日, 時段：',
            confirmText_7: '注意：請提醒民眾在30分鐘內,付完款項。',
            doctorNoService: '該醫師未開班，請重新搜尋',
            partner: '醫事機構',
        },
        specAgent: {
            specAppt: '特約掛號',
            partner: '醫事機構',
            doctor: '醫師',
            selectDoctorMember: '請選擇醫師會員',
            myself: '(自己)',
            myContact: '我的聯絡人',
            whosDoctor: '{partnerName}的醫師',
            date: '日期',
            selectDate: '選擇日期',
            totalTime: '諮詢時長(分鐘)',
            enterMinutes: '輸入諮詢分鐘',
            patient: '民眾',
            patientTip: '請輸入民眾姓名或身分證字號',
            selectPatientMember: '篩選符合民眾',
            time: '時間',
            timeTip: '將於選定時間前三十分鐘提醒雙方',
            selectTime: '選擇時間',
            amount: '金額',
            enterAmount: '請輸入金額',
            vipMember: 'VIP會員',
            newSpecAppt: '新增特約掛號',
            communicationType: '看診方式',
            videoChat: '視訊看診',
            onsiteVisit: '到院看診',
        },
        apptBtn: {
            apptBtn: '掛號',
            appt: '代理掛號',
            specAppt: '特約掛號',
            switch: '切換',
            videoChat: '視訊看診',
            onsiteVisit: '到院看診',
            patient: '民眾',
            patientTip: '請輸入民眾姓名或身分證字號',
            doctor: '醫師',
            myself: '(自己)',
            myContact: '我的聯絡人',
            whosDoctor: '{partnerName}的醫師',
            callBack: '民眾代理人(醫師回撥對象)',
            fee: '費用',
            problem: '諮詢問題',
            dateTimeSetting: '日期時間設定',
            peopleNum: '人數',
            doctorLeave: '醫師請假',
            appointment: '預約',
            timeOut: '時段已過',
            time: '時間',
            timeTip: '將於選定時間前三十分鐘提醒雙方',
            totalTime: '諮詢時長(分鐘)',
            patientInPerson: '病人本人',
            inPerson: '本人',
            am: '上午',
            pm: '下午',
            night: '晚診',
            minutes: '分鐘',
            confirmText_1: '請確定以下資訊：',
            confirmText_2: '預約醫師：',
            confirmText_3: '民眾大名：',
            confirmText_4: '付費方案：',
            confirmText_5: '諮詢時長：',
            confirmText_6: '預約日期：',
            confirmText_7: '預約時段：',
            confirmText_8: '預約時間：',
            confirmText_9: '注意：請提醒民眾在30分鐘內,付完款項。',
            errorMsg1: '請選擇民眾',
            errorMsg2: '請選擇醫師',
            errorMsg3: '請選擇日期',
            errorMsg4: '請輸入費用',
            errorMsg5: '請選擇時間',
            errorMsg6: '請選擇諮詢時長',
            selectCsDoc: '選擇會診醫師',
            consultation: '會診掛號',
        },
        upload: {
            title: '醫療影像上傳',
            patient: '民眾',
            accountTip: '請輸入民眾帳號',
            patientTip: '請輸入民眾姓名或身分證字號',
            uploadType: '檔案分類',
            notes: '備註',
            selectFile: '選擇檔案',
            upload: '上傳',
            feeBack: {
                noPatient: '未選擇民眾',
                noType: '未選擇上傳檔案類型',
                noFile: '未選擇上傳檔案',
                upload_success: '上傳成功',
            },
            type: {
                generalPhoto: '患部照片',
                generalReport: '檢驗報告',
                dicom: 'DICOM',
                funduscopy_R: '眼底鏡圖片-右眼',
                funduscopy_L: '眼底鏡圖片-左眼',
                ITRI_AI: '工研院眼底鏡AI',
                ultrasonograph: '超音波照片',
                ecg: '心電圖',
                ear: '五官鏡-耳道',
                nasal: '五官鏡-鼻腔',
                throat: '五官鏡-咽喉',
                oral: '五官鏡-口腔',
                skin: '五官鏡-皮膚',
                phono: '心電心音',
                video: '一般影片',
            },
            accept: {
                0: '允許.pdf或.jpg、.png等圖片檔案',
                1: '允許.pdf或.jpg、.png等圖片檔案',
                2: '只允許.dcm的檔案',
                3: '允許.pdf或.jpg、.png等圖片檔案',
                4: '允許.pdf或.jpg、.png等圖片檔案',
                5: '允許.pdf或.jpg、.png等圖片檔案',
                6: '允許.pdf或.jpg、.png等圖片檔案',
                7: '允許.pdf或.jpg、.png等圖片檔案',
                8: '允許.pdf或.jpg、.png等圖片檔案',
                9: '允許.pdf或.jpg、.png等圖片檔案',
                10: '允許.pdf或.jpg、.png等圖片檔案',
                11: '只允許影片的檔案',
            },
        },
        sendMsg: {
            title: '{name}的訊息視窗',
            retractMsg: '{name}已收回訊息',
            msgIsSeen: '已讀',
            send: '傳送',
            retractTip: '確認收回此筆資料？',
        },
        selectPartner: {
            choosePartner: '請選擇醫事機構',
            today: '今日：',
            all: '所有：',
        },
    },
    // 醫事人員類別
    staffType: {
        Doc: '醫師',
        Nur: '護理師',
        Pha: '藥師',
        Cus: '客服',
        Care: '照服員',
        Dietitian: '營養師',
        Psy: '諮商心理師',
        Clinical: '臨床心理師',
        Phy: '物理治療師',
        Func: '職能治療師',
        Heal: '健康管理師',
        Radio: '醫事放射師',
        Exam: '醫檢師',
        Case: '個案管理師',
        HSec: '健康秘書',
        Vol: '志工師',
    },
    // 合作夥伴
    partner: {
        KeelungChangGungMemorialHospital: '基隆長庚醫院',
        AllianceTitle: '遠距之愛',
        ChengHsinTitle: '振興醫院',
        CLCTitle: 'CLC CARE',
        PingChrisTitle: '屏東基督教醫院',
        CamiansaintTitle: '羅東聖母醫院',
        CurdrTitle: '醫生馬上看',
    },
    // element UI 元件
    el: {
        colorpicker: {
            confirm: '確認',
            clear: '清空',
        },
        datepicker: {
            now: '現在',
            today: '今天',
            cancel: '取消',
            clear: '清空',
            confirm: '確認',
            selectDate: '選擇日期',
            selectTime: '選擇時間',
            selectDateTime: '選擇日期時間',
            startDate: '開始日期',
            startTime: '開始時間',
            endDate: '結束日期',
            endTime: '結束時間',
            prevYear: '前一年',
            nextYear: '後一年',
            prevMonth: '上個月',
            nextMonth: '下個月',
            year: '年',
            month1: '1 月',
            month2: '2 月',
            month3: '3 月',
            month4: '4 月',
            month5: '5 月',
            month6: '6 月',
            month7: '7 月',
            month8: '8 月',
            month9: '9 月',
            month10: '10 月',
            month11: '11 月',
            month12: '12 月',
            week: '周次',
            weeks: {
                sun: '日',
                mon: '一',
                tue: '二',
                wed: '三',
                thu: '四',
                fri: '五',
                sat: '六',
            },
            months: {
                jan: '一月',
                feb: '二月',
                mar: '三月',
                apr: '四月',
                may: '五月',
                jun: '六月',
                jul: '七月',
                aug: '八月',
                sep: '九月',
                oct: '十月',
                nov: '十一月',
                dec: '十二月',
            },
        },
        select: {
            loading: '加載中',
            noMatch: '無匹配資料',
            noData: '無資料',
            placeholder: '請選擇',
        },
        cascader: {
            noMatch: '無匹配資料',
            loading: '加載中',
            placeholder: '請選擇',
            noData: '無資料',
        },
        pagination: {
            goto: '前往',
            pagesize: '項/頁',
            total: '共 {total} 項',
            pageClassifier: '頁',
        },
        messagebox: {
            title: '提示',
            confirm: '確定',
            cancel: '取消',
            error: '輸入的資料不符規定!',
        },
        upload: {
            deleteTip: '按 delete 鍵可刪除',
            delete: '刪除',
            preview: '查看圖片',
            continue: '繼續上傳',
        },
        table: {
            emptyText: '暫無資料',
            confirmFilter: '篩選',
            resetFilter: '重置',
            clearFilter: '全部',
            sumText: 'Sum',
        },
        tree: {
            emptyText: '暫無資料',
        },
        transfer: {
            noMatch: '無匹配資料',
            noData: '無資料',
            titles: ['List 1', 'List 2'],
            filterPlaceholder: 'Enter keyword',
            noCheckedFormat: '{total} items',
            hasCheckedFormat: '{checked}/{total} checked',
        },
        image: {
            error: '加載失敗',
        },
        pageHeader: {
            itle: '返回',
        },
        popconfirm: {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        },
    },
    // 系統訊息
    message: {
        data_not_input: '資料未輸入',
        upload_success: '上傳成功',
        upload_error: '上傳失敗',
        upload_file_error: '上傳失敗!請檢查檔案大小或檔案格式!',
        data_apdate_success: '資料更新成功',
        search_full_message: '請於搜尋列表輸入完整的身分證/電話/居留證!',
        search_full_name_account: '請於搜尋列表輸入完整的姓名或帳號!',
        confirm_delete_record: '確認刪除此筆資料',
        send_success: '傳送成功',
        send_error: '傳送失敗',
        delete_success: '刪除成功',
        delete_error: '刪除失敗',
        register_again: '重新掛號',
        register_success: '掛號成功',
        register_error: '掛號失敗',
        register_finish: '完成看診',
        checkField: '請檢查相關欄位!',
        update_success: '更新成功',
        update_error: '更新失敗',
        create_success: '新增成功',
        create_error: '新增失敗',
        save_success: '儲存成功',
        save_error: '儲存失敗',
        invite_success: '邀請成功',
        invite_error: '邀請失敗',
        copy_success: '複製成功',
        copy_error: '複製失敗',
        assign_success: '交辦完成',
        assign_error: '交辦失敗',
    },
    //飲食管理
    dietmgmt: {
        dietmgmt: '飲食管理',
        Height: '身高(CM)',
        Weight: '體重(KG)',
        NoData: '無資料',
        Analyze: '待分析',
        MealDate: '用餐日期',
        MealDescription: '民眾餐點描述',
        EstimatedKCals: '民眾估計卡路里',
        FoodName: '食物名稱',
        ApplyMealDescription: '套用餐點描述',
        ApplyEstimatedKCals: '套用估計卡路里',
        Suggest: '建議',
        DietAnalyze: '飲食分析',
        NextPhoto: '下一張',
        Carbohydrate: '碳水化合物',
        Protein: '蛋白質',
        Fat: '脂肪',
        Water: '飲水量',
        Gram: '公克',
        Ml: '(毫升)',
        Status: '狀態',
        WholeMilk: '全脂牛奶',
        LowMilk: '低脂牛奶',
        SkimMilk: '脫脂牛奶',
        Fruit: '水果類',
        Vegetables: '蔬菜類',
        Staple: '主食類',
        HighFatMeat: '肉類(高脂)',
        MediumFatMeat: '肉類(中脂)',
        LowFatMeat: '肉類(低脂)',
        MediumLegumes: '豆製品(中脂)',
        LowLegumes: '豆製品(低脂)',
        Grease: '油脂類',
        Quantity: '數量',
        TodaysCarbohydrate: '本日碳水',
        TodaysProtein: '本日蛋白質',
        TodaysFat: '本日脂肪',
        TodaysCal: '本日卡路里',
        Unit: '單位(份)',
        Units: '份',
        analyze: '分析',
        DietInformation: '飲食資訊',
        MealTime: '用餐時間',
        MealDescription: '民眾描述',
        kcal: '大卡',
        Save: '儲存',
        Calorie: '卡路里',
        UpdateRecomm: '修改建議值',
        FirstMeal: '第一餐',
        SecondMeal: '第二餐',
        ThirdMeal: '第三餐',
        ForthMeal: '第四餐',
        FifthMeal: '第五餐',
        SixthMeal: '第六餐',
        Recomm: '建議值：',
        notSetRecomm: '建議值未設定',
        Save: '儲存',
        noData: '未上傳',
        Analyzed: '已分析',
        call: '撥打 ',
    },
    //問卷管理
    Question: {
        questionmgmt: '問卷管理',
        writer: '填寫人',
        answer_member: '查看回覆',
    },
    // 常用彈出視窗元件
    popup: {
        alert: '提示',
        confirm: '確定',
        cancel: '取消',
        add: '新增',
        save: '儲存',
        update: '更新',
        edit: '編輯',
        reset: '重設',
    },
    HRV: {
        golden_hrv: {
            1: {
                title: '心力狀態',
                order: ['905', '904', '903', '902', '901'],
                options: {
                    905: '心灰意冷',
                    904: '心累',
                    903: '正常範圍',
                    902: '瑣事煩心',
                    901: '心煩易怒',
                },
                graph: '1',
            },
            2: {
                title: '體力狀態',
                order: ['910', '909', '908', '907', '906'],
                options: {
                    910: '體力透支',
                    909: '疲憊',
                    908: '正常範圍',
                    907: '拼勁十足',
                    906: '過度暴衝',
                },
                graph: '1',
            },
            3: {
                title: '心情穩定度',
                order: ['915', '914', '913', '912', '911'],
                options: {
                    915: '過度低落',
                    914: '低落',
                    913: '正常範圍',
                    912: '起伏大',
                    911: '起伏太大',
                },
                graph: '1',
            },
            4: {
                title: '壓力緊張度',
                order: ['920', '919', '918', '917', '916'],
                options: {
                    920: '過度鬆散',
                    919: '鬆散',
                    918: '正常範圍',
                    917: '緊張',
                    916: '過度緊張',
                },
                graph: '1',
            },
            5: {
                title: '心理疲勞度',
                order: ['957', '924', '923', '922', '921'],
                options: {
                    957: '正常範圍',
                    924: '略疲勞',
                    923: '疲勞',
                    922: '太疲勞',
                    921: '過度疲勞',
                },
                graph: '2',
            },
            6: {
                title: '身體疲勞度',
                order: ['958', '928', '927', '926', '925'],
                options: {
                    958: '正常範圍',
                    928: '略疲勞',
                    927: '疲勞',
                    926: '太疲勞',
                    925: '過度疲勞',
                },
                graph: '2',
            },
            7: {
                title: '壓力累積度',
                order: ['933', '932', '931', '930', '929'],
                options: {
                    933: '中長期壓力極大',
                    932: '中長期壓力大',
                    931: '正常範圍',
                    930: '短期壓力大',
                    929: '短期壓力極大',
                },
                graph: '1',
            },
            8: {
                title: '長期壓力',
                order: ['938', '937', '936', '935', '934'],
                options: {
                    938: '身體極度想要休息',
                    937: '身體想要休息',
                    936: '正常範圍',
                    935: '長期壓力大',
                    934: '長期壓力極大',
                },
                graph: '1',
            },
            9: {
                title: '消化機能',
                order: ['943', '942', '941', '940', '939'],
                options: {
                    943: '極差',
                    942: '略差',
                    941: '正常範圍',
                    940: '抑制',
                    939: '過度抑制',
                },
                graph: '1',
            },
            10: {
                title: '睡眠狀態',
                order: ['948', '947', '946', '945', '944'],
                options: {
                    948: '白天嗜睡',
                    947: '白天想睡',
                    946: '正常範圍',
                    945: '夜晚不易入睡',
                    944: '夜晚無法入睡',
                },
                graph: '1',
            },
            11: {
                title: '夢境品質',
                order: ['959', '952', '951', '950', '949'],
                options: {
                    959: '正常範圍',
                    952: '夢略多',
                    951: '多夢',
                    950: '極多夢',
                    949: '多夢紛擾',
                },
                graph: '2',
            },
            12: {
                title: '睡眠深熟度',
                order: ['960', '956', '955', '954', '953'],
                options: {
                    960: '正常範圍',
                    956: '淺眠',
                    955: '中斷易醒',
                    954: '不易入睡',
                    953: '無法入睡',
                },
                graph: '2',
            },
            46: {
                note: 'SDNN',
            },
        },
    },
    freeclinic: {
        freeclinicEventList: '現在沒有進行中的義檢活動，或您未參加活動',
    },
}
